import axios from 'axios'
// import qs from 'qs'
import store from '@/store'
import router from '@/router'
import { Message } from 'element-ui';
import { refreshToken } from '@/api/user.js'
// import { resolve } from 'core-js/fn/promise'
// message.config({
//   maxCount: 3,
// })
function message(e) {
  Message({
    message: e,
    type: 'error'
  })
}
// create an axios instance
const request = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 15000, // request timeout
})
// request interceptor
request.interceptors.request.use(
  (config) => {
    // do something before request is sent
    if (config.needTimeout && typeof config.needTimeout == 'number') {
      config.timeout = config.needTimeout
    }
    if (config.needToken) {
      let tempToken = store.getters['user/token']
      const token = 'Bearer ' + tempToken
      config.headers['Authorization'] = token
    }
    // 趣简章自定义 header 参数
    config.headers['sysType'] = 'gr_api'
    // 趣简章自定义 header 参数 登录用表单形式提交
    if (config.isForm) {
      config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
    }
    if (config.workGroupId) {
      config.headers['workGroupId'] = store.getters['user/usersId']
    }

    return config
  },
  (error) => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
request.interceptors.response.use(
  (response) => {
    const res = response.data
    console.log('请求结果：', res)
    // if the custom code is not 20000, it is judged as an error.
    return res
  },
  (error) => {
    let response = error.response
    if (error.toString() === 'Error: timeout of 15000ms exceeded') {
      message('请求超时,请刷新页面重试')
      // message.info('请求超时,请刷新页面重试', 10)
    } else if (error.response.data.error === 'invalid_token') {
      // token失效
      if (response.config.url !== '/auth/oauth2/token') {
        // 非刷新token,获取新token
        // 用来记录上次的请求信息
        store.dispatch('user/resetToken').then(() => {
          router.push({
            path: `/login?redirect=${router.currentRoute.fullPath}`,
          })
          resolve()
        })
        return
      }
    } else {
      message(response.data.message || error.message)
    }
    return Promise.reject(new Error(response.data.message || 'Error'))
  }
)

const get = (
  url,
  params,
  isForm = false,
  needToken = false,
  workGroupId = true
) => {
  return request({
    url,
    params,
    method: 'get',
    isForm,
    needToken,
    workGroupId,
  })
}

const post = (
  url,
  params,
  isForm = false,
  needToken = true,
  workGroupId = true,
  needTimeout = 15000
) => {
  return request({
    url,
    // data: qs.stringify(params),
    data: params,
    method: 'post',
    isForm,
    needToken,
    workGroupId,
    needTimeout,
  })
}

const put = (
  url,
  params,
  isForm = false,
  needToken = false,
  workGroupId = true
) => {
  return request({
    url,
    data: params,
    method: 'put',
    isForm,
    needToken,
    workGroupId,
  })
}

const del = (
  url,
  params,
  isForm = false,
  needToken = false,
  workGroupId = true
) => {
  return request({
    url,
    data: params,
    method: 'delete',
    isForm,
    needToken,
    workGroupId,
  })
}

export default {
  get,
  post,
  put,
  del,
  request,
}
