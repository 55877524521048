// import Vue from 'vue'
// import VueRouter from 'vue-router'

// Vue.use(VueRouter)
import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

// 解决报错
const originalPush = Router.prototype.push
const originalReplace = Router.prototype.replace
// push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch((err) => err)
}
// replace
Router.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch((err) => err)
}
const constantRoutes = [

  {
    path: '/',
    name: 'index',
    component: () => import('@/views/index.vue'),
    meta: {
      index: 1
    }
  },
  {
    path: '/vip',
    name: 'vip',
    component: () => import('@/views/vip.vue'),
    meta: {
      index: 1
    }
  },
  {
    path: '/record',
    name: 'record',
    component: () => import('@/views/record.vue'),
    meta: {
      index: 1
    }
  },
  {
    path: '/Login',
    name: 'Login',
    component: () => import('@/views/Login.vue'),
    meta: {
      index: 1
    }
  },
  {
    path: '/usingVideos',
    name: 'usingVideos',
    component: () => import('@/views/usingVideos/index.vue'),
    meta: {
      index: 1
    }
  },
  {
    path: '/agreement',
    name: 'agreement',
    component: () => import('@/views/agreement.vue'),
    meta: {
      index: 1
    }
  },
  {
    path: '/paySuccess',
    name: 'paySuccess',
    component: () => import('@/views/paySuccess/paySuccess.vue'),
    meta: {
      index: 1
    }
  },
]

const createRouter = () =>
  new Router({
    // mode: 'history', // require service support
    scrollBehavior: () => ({
      y: 0,
    }),
    routes: constantRoutes,
  })

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
