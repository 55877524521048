/* eslint-disable */
import {
  login,
  resetPassword,
  getInfo
} from '@/api/user'
import {
  getSchoolInfo
} from '@/api/userInfo.js'
// import router, {
//   resetRouter
// } from '@/router'
const state = {
  token: '',
  name: '',
  avatar: '',
  usersId: null,
  introduction: '',
  userIdentity: '', // 用户身份 designer设计师 client用户
  roles: [],
  systemCreateDatetime: ''
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
    localStorage.setItem('official_accessToken', token) // 新切换后台用
  },
  SET_INTRODUCTION: (state, introduction) => {
    state.introduction = introduction
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles
    localStorage.setItem('official_roles', roles)
  },
  SET_USERSID: (state, id) => {
    state.usersId = id
    localStorage.setItem('official_usersId', id)
    localStorage.setItem('official_workGroupId', id)

  },
  SET_IDENTITY: (state, payload) => {
    state.userIdentity = payload
    localStorage.setItem('official_userIdentity', payload)
  },
  SET_DATETIME: (state, payload) => {
    state.systemCreateDatetime = payload
    localStorage.setItem('official_systemCreateDatetime', payload)
  }
}

const actions = {
  // user login
  login(context, userInfo) {
    var isRegister = false
    if (userInfo['code'].length > 0) {
      userInfo['mobile'] = userInfo.username.trim(),
        isRegister = true
    }
    return new Promise((resolve, reject) => {
      login(userInfo, isRegister).then(data => {
        console.log('登录成功后返回')
        localStorage.setItem('refreshtoken', data.refresh_token)
        context.commit('SET_TOKEN', data.access_token)
        // context.dispatch('getInfo')
        // context.dispatch('menu/loadRoutes', null, {
        //   root: true
        // })

        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  // user psw reset
  resetPsw(context, userInfo) {
    userInfo['mobile'] = userInfo.username.trim()
    return resetPassword(userInfo)
  },

  // get user info
  getInfo({
    commit,
    state
  }) {
    return new Promise((resolve, reject) => {
      getInfo().then(response => {
        const {
          data
        } = response
        if (!data) {
          reject('Verification failed, please Login again.')
        }

        commit('SET_ROLES', [data.isDesigner ? 'Designer' : 'User'])
        commit('SET_IDENTITY', data.isDesigner ? 'designer' : 'client')
        if (data.usersInfo) {
          const {
            usersInfo
          } = data
          // localStorage.setItem('isDesigner', data.isDesigner)
          if (data.isDesigner === false) {
            commit('SET_USERSID', usersInfo.id)
            commit('SET_NAME', usersInfo.nickname)
            commit('SET_AVATAR', usersInfo.avatar)
            //  登录账号是学校，缓存当前的学校信息
            getSchoolInfo().then(res => {
              localStorage.setItem('schoolInfo', JSON.stringify(res.data))
              commit('SET_DATETIME', res.data.systemCreateDatetime)
            })
          }
        }

        resolve(state.roles)
      }).catch(error => {
        reject(error)
      });
    })
  },

  // user logout
  logout({
    commit,
    state,
    dispatch
  }) {
    return new Promise((resolve, reject) => {
      dispatch('resetToken')
      window.location.reload()
      resolve()
    })
  },

  // remove token
  resetToken({
    commit,
    dispatch
  }) {
    localStorage.setItem('refreshtoken', '')
    commit('SET_TOKEN', '')
    commit('SET_ROLES', [])
    commit('SET_USERSID', '')
    commit('SET_NAME', '')
    commit('SET_AVATAR', '')
    commit('SET_IDENTITY', '')
    // 需要把缓存的路由数组也清理掉
    // dispatch('menu/resetMenu', null, {
    //   root: true
    // })
    // resetRouter()
  }
}

const getters = {
  token(state) {
    let token = localStorage.getItem('official_accessToken')
    return state.token || token
  },
  usersId(state) {
    let id = localStorage.getItem('official_usersId')
    return state.usersId || id
  },
  name(state) {
    return state.name
  },
  avatar(state) {
    return state.avatar
  },
  systemCreateDatetime(state) {
    let id = localStorage.getItem('official_systemCreateDatetime')
    return state.systemCreateDatetime || id
  },
  roles(state) {
    return state.roles
  },
  userIdentity(state) {
    return state.userIdentity
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
/* eslint-disable */
