<template>
  <div class="header">
    <div class="headerLeft">
      <img
        class="logo"
        src="https://qiniu-z0-qjz.qujianzhang.com/3361581058556928/user/image/FmHh4Ir5Mfw8kvVBKELHyMhMWzhw.jpg"
        alt=""
      />

      <div class="menu">
        <router-link to="/">首页</router-link>
        <router-link to="/vip">下单与发票</router-link>
        <a :href="openLinkUrl" target="_blank">趣简章后台</a>
      </div>
    </div>

    <div class="headerRight" v-if="userId">
      <div class="headerBtn">
        <el-button type="primary">
          <i><img :src="guard" /></i>
          安全守护{{ day }}天
        </el-button>
        <el-button type="primary" @click="$router.push('/usingVideos')">
          <i><img :src="tip" /></i>
          系统提示
        </el-button>
        <el-button type="primary" @click="promptDialog">
          <i><img :src="phone" /></i>
          联系我们
        </el-button>
      </div>
      <el-dropdown>
        <img :src="avatars" alt="" />
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item @click.native="gotoRecord(1)"
            >发票记录</el-dropdown-item
          >
          <el-dropdown-item @click.native="gotoRecord(2)"
            >订单记录</el-dropdown-item
          >
          <el-dropdown-item @click.native="tuichu">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <PromptDialog ref="promptdialog" />
  </div>
</template>
<script>
import "../index.scss";
import { mapState } from "vuex";
import PromptDialog from "./promptDialog.vue"; //联系我们
export default {
  data: function () {
    return {
      guard:
        "https://qiniu-z0-qjz.qujianzhang.com/3153402324632576/visual_H5/userimage/image/FtkxDiM4JM9-Nj_kOTgNV9XTbSLe.png",
      tip: "https://qiniu-z0-qjz.qujianzhang.com/3153402324632576/visual_H5/userimage/image/Fhn1_hk3lyG8ocZnX5VK9acYrYHi.png",
      phone:
        "https://qiniu-z0-qjz.qujianzhang.com/3153402324632576/visual_H5/userimage/image/FoLsrZqnYB4htmlDdgZtJoHEYjMW.png",
      day: null,
      userId: null,
      avatars: "",
      headerAvatars:
        "https://qiniu-z0-qjz.qujianzhang.com/3165201816224768/user/image/FqpxtRMqmK04awGO-tD556rrOZFe.png",
      startTime: null,
      openLinkUrl: null,
    };
  },
  components: { PromptDialog },
  computed: {
    ...mapState("user", ["avatar", "usersId", "systemCreateDatetime"]),
  },
  watch: {
    usersId(newData) {
      this.userId = newData;
    },
    avatar(newData) {
      this.avatars = newData || this.headerAvatars;
    },
    systemCreateDatetime(newData) {
      this.startTime =
        newData || this.$store.getters["user/systemCreateDatetime"];
      let now = new Date().getTime();
      this.day = this.daysBetween(Number(this.startTime), now);
    },
  },
  created() {
    this.userId = this.$store.getters["user/usersId"];
    this.avatars = this.$store.getters["user/avatar"] || this.headerAvatars;
    this.startTime = this.$store.getters["user/systemCreateDatetime"];
    let now = new Date().getTime();
    this.day = this.daysBetween(Number(this.startTime), now);
    let str = process.env.VUE_APP_BOOK_URL;
    this.openLinkUrl = `${str}/lite`;
  },
  methods: {
    // 安全守护
    daysBetween(timestamp1, timestamp2) {
      // 一天的毫秒数
      var oneDay = 1000 * 60 * 60 * 24;
      // 将时间戳转换为日期对象
      var date1 = new Date(timestamp1);
      var date2 = new Date(timestamp2);
      // 计算两个日期之间的毫秒差值
      var timeDiff = Math.abs(date2.getTime() - date1.getTime());
      // 将毫秒差值转换为天数
      var diffDays = Math.ceil(timeDiff / oneDay);
      return diffDays;
    },
    //联系我们
    promptDialog() {
      this.$refs.promptdialog.openDialog();
    },
    gotoRecord(type) {
      this.$router.push(`/record?type=${type}`);
    },
    tuichu() {
      this.$store.dispatch("user/logout");
      localStorage.clear();
      sessionStorage.clear();
    },
  },
};
</script>
<style scoped lang="scss">
a {
  text-decoration: none;
  color: #333333;
  font-size: 16px;
}
.router-link-exact-active {
  color: #ef0913 !important;
}
.header {
  height: 64px;
  padding: 0 30px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .headerLeft {
    display: flex;
    align-items: center;
    flex: 1;
    .logo {
      display: inline-block;
      width: 120px;
      height: 40px;
      color: white;
      font-size: 16px;
    }
    .menu {
      margin-left: 7%;
      a {
        font-size: 16px;
        color: #333333;
        margin-right: 38px;
      }
    }
  }
  .headerRight {
    display: flex;
    align-items: center;
    .headerBtn {
      margin-right: 30px;
      display: inline-block;

      img {
        width: 18px;
        height: 18px;
        margin-right: 6px;
        vertical-align: text-bottom;
      }

      .el-button {
        padding: 6px 12px;
      }

      .el-button--primary {
        border-radius: 8px;
        font-size: 14px;
        font-weight: bold;
      }

      .el-button--primary:nth-child(1) {
        color: #7a330d;
        background: linear-gradient(to bottom, #fbeeda, #ffe7c4);
        border: 1px solid #fbeeda;
      }

      .el-button--primary:nth-child(2) {
        color: #2254f4;
        background-color: #f0f6ff;
        border: 1px solid #f0f6ff;
      }

      .el-button--primary:nth-child(3) {
        color: #ef0913;
        background-color: #fff3f4;
        border: 1px solid #fff3f4;
      }
    }
    .el-dropdown {
      img {
        width: 42px;
        height: 42px;
        border-radius: 50%;
      }
    }
  }
}
</style>
