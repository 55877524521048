import request from '@/utils/request'
import qs from 'qs'
export function login(data, isRegister) {
  console.log('调用登录接口', data)
  return request.post(isRegister ? `${process.env.VUE_APP_VISUAL_PREFIX}/mobile/register` : `${process.env.VUE_APP_VISUAL_PREFIX}/login`, qs.stringify(data), true, false)
}

export function getCodeApi(mobile) {
  return request.post(`/auth/send/sms/${mobile}`, {}, false, false)
}

export function getInfo() {
  return request.get(`${process.env.VUE_APP_VISUAL_PREFIX}/custom/designer/backend/get`, {}, false, true)
}

export function resetPassword({ mobile, code, password }) {
  let param = { mobile: mobile, code: code, password: password }
  return request.post('/auth/custom/user/notCheck/reset/password', qs.stringify(param), true, false)
}
export function gettemptoken() {
  return request.post(`${process.env.VUE_APP_VISUAL_PREFIX}/temp/token`, {}, false, true)
}
export function getAllMenu() {
  return request.get(`${process.env.VUE_APP_GR_PREFIX}/custom/menu/designerOrSchool/allMenu?siteType=lite`, {}, false, true)
}
export function refreshToken(data) {
  return request.post(
    `/auth/oauth2/token`,
    qs.stringify(data),
    true,
    false,
    false
  )
}
