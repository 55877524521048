<template>
  <div>
    <el-dialog :title="title" class="c-dialog-fixed" :visible.sync="show" :close-on-click-modal="false" append-to-body width="500px" @open="openDialog" @close="closeDialog">
      <div>
        <div class="whatimg">
          <img src="https://qiniu-z0-qjz.qujianzhang.com/system/backend-creator/zhigeweichat.png" />
        </div>
      </div>
    </el-dialog>
  </div>
</template>
  
  <script>
export default {
  name: 'promptDialog',
  props: {
    txtShow: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      title: '',
      show: false
    }
  },
  computed: {},
  mounted() { },
  created() { },
  methods: {
    openDialog() {
      this.show = true
    },
    closeDialog() {
      this.show = false
    }
  }
}
  </script>
  <style scoped>
.whatimg {
  width: 250px;
  height: 250px;
  margin: 0 auto;
  padding: 30px;
}

.whatimg img {
  width: 100%;
  height: 100%;
}
</style>
  