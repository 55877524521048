<template>
  <div id="app">
    <el-container>
      <el-header v-if="showcenter">
        <Header />
      </el-header>
      <el-main>
        <router-view></router-view>
      </el-main>
      <el-footer v-if="footer1">
        <Footer />
      </el-footer>
    </el-container>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Header from '@/components/header/header.vue';
import Footer from '@/components/footer/index.vue';
export default {
  name: 'App',
  components: {
    Header, Footer
  },
  data: function () {
    return {
      showcenter: true,
      footer1: true
    }
  },
  computed: {
    ...mapState(['user']), // 使用 Vuex 的辅助函数来获取 state 中的数据  
  },
  watch: {
    '$route'(newType, oldType) {
      if (newType.path == '/paySuccess') {
        this.footer1 = false
      } else {
        this.footer1 = true
      }
      if (newType.path == '/record' || newType.path == '/paySuccess') {
        this.showcenter = false
      } else {
        this.showcenter = true
      }
    },
    user(newData) {
      console.log(newData, 999);
      // 当 data 变化时，执行某些操作  
    },
  },
}
</script>

<style scoped>
#app {
  min-width: 1200px;
}
::v-deep .el-header {
  padding: 0;
}
::v-deep .el-card__body,
::v-deep .el-main,
::v-deep .el-footer {
  padding: 0;
}
p {
  margin: 0;
}
</style>
