import request from '@/utils/request'
export function schoolsSave(payload) {
  return request.post(`${process.env.VUE_APP_VISUAL_PREFIX}/schools`, payload, false, true, true, true)
}
export function schoolsPut(payload) {
  return request.put(`${process.env.VUE_APP_STORE_PREFIX}/custom/store/update`, payload, false, true, false)
}
export function getSchoolInfo(payload) {
  return request.get(`${process.env.VUE_APP_STORE_PREFIX}/custom/store/get`, payload, false, true, false)
}
// 获取功能视频
export function getUseVideo(payload) {
  return request.get(`${process.env.VUE_APP_VISUAL_PREFIX}/custom/useTutorials/backend/notCheck/page?isShow=true`, payload, false, true, false)
}
